'use client'

import logo from "../images/logo.png"
import image_1 from "../images/image_1.webp"
import image_2 from "../images/image_2.webp"
import image_3 from "../images/image_3.webp"
import image_4 from "../images/image_4.webp"
import image_5 from "../images/image_5.webp"

import './LandingPage.css'

export default function Example() {

    return (
        <div className="bg-white">
<header className="absolute inset-x-0 top-0 z-50">
    <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between py-8">
        <div className="w-full flex justify-start sm:mx-auto sm:flex sm:justify-center px-6">
            <a href="/" className="-m-1.5 p-1.5">
                <span className="sr-only">Gentle Hawk</span>
                <img
                    alt=""
                    src={logo}
                    className="h-24 w-auto"
                />
            </a>
        </div>
    </nav>
</header>


            <main className="min-h-screen flex justify-center items-center pt-8">
                <div className="relative isolate max-w-full">
                    <svg
                        aria-hidden="true"
                        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                    >
                        <defs>
                            <pattern
                                x="50%"
                                y={-1}
                                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                width={200}
                                height={200}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" width="100%" height="100%" strokeWidth={0} />
                    </svg>
                    <div
                        aria-hidden="true"
                        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                    >
                        <div
                            style={{
                                clipPath:
                                    'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                            }}
                            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#000000] to-[#cccccc] opacity-30"
                        />
                    </div>
                    <div className="overflow-hidden">
                        <div className="mx-auto px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                                <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                                    <h1 className="font-public text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                        Pixels and algorithms
                                    </h1>
                                    <p className="font-public mt-6 text-md leading-8 text-black sm:max-w-md lg:max-w-none">
                                        We navigate the intersection of code and creativity, transforming abstract concepts into elegant software and immersive games. Each project is a canvas for innovation, where technical precision meets imaginative design. As architects in the digital realm, we shape experiences that redefine engagement, interaction, and persuasion.
                                    </p>
                                    <div className="mt-10 flex items-center gap-x-6">
                                        <a
                                            href="mailto:andrew@gentlehawk.com"
                                            className="outline font-public rounded-md bg-neutral-950 px-3.5 py-2.5 text-sm text-white shadow-sm hover:bg-neutral-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-neutral-950"
                                        >
                                            Contact us
                                        </a>
                                    </div>
                                </div>
                                <div className="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                                    <div className="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                                        <div className="relative pulse" style={{ animationDelay: '0s' }}>
                                            <img
                                                alt=""
                                                src={image_1}
                                                className="aspect-[2/3] w-full rounded bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                    <div className="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
                                        <div className="relative pulse" style={{ animationDelay: '2s' }}>
                                            <img
                                                alt=""
                                                src={image_2}
                                                className="aspect-[2/3] w-full rounded bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="relative pulse" style={{ animationDelay: '4s' }}>
                                            <img
                                                alt=""
                                                src={image_3}
                                                className="aspect-[2/3] w-full rounded bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                    <div className="w-44 flex-none space-y-8 pt-32 sm:pt-0">
                                        <div className="relative pulse" style={{ animationDelay: '6s' }}>
                                            <img
                                                alt=""
                                                src={image_4}
                                                className="aspect-[2/3] w-full rounded bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                        <div className="relative pulse" style={{ animationDelay: '8s' }}>
                                            <img
                                                alt=""
                                                src={image_5}
                                                className="aspect-[2/3] w-full rounded bg-gray-900/5 object-cover shadow-lg"
                                            />
                                            <div className="pointer-events-none absolute inset-0 rounded ring-1 ring-inset ring-gray-900/10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <footer className="bg-white">
                <div className="mx-auto max-w-7xl overflow-hidden px-6 pb-20 sm:pb-24 lg:px-8">


                    <p className="font-public mt-10 text-center text-xs leading-5 text-gray-500">
                        &copy; {new Date().getFullYear()} Gentle Hawk, LLC. All rights reserved.
                    </p>
                </div>
            </footer>
        </div>
    )
}
